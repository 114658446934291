var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showControl)?_c('div',[_c('ValidationObserver',{ref:"covObserver",attrs:{"tag":"span"}},[(_vm.showCovRow('A'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"9","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovA',
                type: 'TextInput',
                value: _vm.Coverages.A,
                //DataServiceValue: CoverageObjects.A.DataServiceValue,
                //DataServiceSource: CoverageObjects.A.DataServiceSource,
                config: {
                    label: 'Coverage A: Dwelling',
                    rules: 'required|min_value:1|max_value:99999999',
                    short: true,
                    col: 12,
                    prefix: '$',
                    default: 0,
                    currency: true,
                    disabled: _vm.disabled,
                    dataServiceValueLabel: 'Suggested Coverage A',
                    dataServiceValueFormat: 'currency',
                    min: 0,
                    allowNegative: false,
                    defaultValue: 0,
                    hideDataServiceValue: true,
                    ignoreDataServiceValue: true
                }
            }},on:{"change":function($event){return _vm.coverageChange('A', $event)},"keydown":_vm.CovAKeydown,"blur":_vm.CovABlur}})],1)],1):_vm._e(),(_vm.showCovAAttestation)?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 pb-2",attrs:{"md":"12","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovAAttestation',
                type: 'Attestation',
                value: _vm.CovAAttestation,
                config: {
                    label: _vm.CovAAttestationLabel,
                    rules: 'required',
                    col: 12,
                    disabled: _vm.disabled,
                    short: true
                }
            }},on:{"change":function($event){return _vm.CovAAttestationChange($event)}}})],1)],1):_vm._e(),(_vm.showCovRow('B'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovB',
                type: 'TextInput',
                value: _vm.Coverages.B,
                //DataServiceValue: CoverageObjects.B.DataServiceValue,
                //DataServiceSource: CoverageObjects.B.DataServiceSource,
                config: {
                    label: 'Coverage B: Other Structures',
                    rules: 'required|max_value:99999999',
                    short: true,
                    col: 12,
                    prefix: '$',
                    currency: true,
                    disabled: _vm.disabled,
                    min: 0,
                    allowNegative: false,
                    defaultValue: 0
                }
            }},on:{"change":function($event){return _vm.coverageChange('B', $event)}}})],1),_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"3","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovBPercent',
                type: 'PercentIncrementer',
                value: _vm.Percents.B,
                config: {
                  disabled: _vm.disabled,
                  short: true
                }
            }},on:{"change":function($event){return _vm.percentChange('B', $event)}}})],1)],1):_vm._e(),(_vm.showCovRow('C'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":_vm.CoverageOption == 'ho4' ? 9 : 6,"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovC',
                type: 'TextInput',
                value: _vm.Coverages.C,
                //DataServiceValue: CoverageObjects.C.DataServiceValue,
                //DataServiceSource: CoverageObjects.C.DataServiceSource,
                config: {
                    label: 'Coverage C: Personal Property',
                    rules: 'required|min_value:0|max_value:99999999',
                    short: true,
                    col: 12,
                    prefix: '$',
                    currency: true,
                    disabled: _vm.disabled,
                    min: 0,
                    allowNegative: false,
                    defaultValue: 0
                }
            }},on:{"change":function($event){return _vm.coverageChange('C', $event)},"keydown":_vm.CovCKeydown}})],1),(_vm.CoverageOption != 'ho4')?_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"3","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovCPercent',
                type: 'PercentIncrementer',
                value: _vm.Percents.C,
                config: {
                  disabled: _vm.disabled,
                  short: true
                }
            }},on:{"change":function($event){return _vm.percentChange('C', $event)}}})],1):_vm._e()],1):_vm._e(),(_vm.showCovRow('D'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovD',
                type: 'TextInput',
                value: _vm.Coverages.D,
                //DataServiceValue: CoverageObjects.D.DataServiceValue,
                //DataServiceSource: CoverageObjects.D.DataServiceSource,
                config: {
                    label: _vm.CoverageOption === 'dp3' ? 'Coverage D: Fair Rental Value' : 'Coverage D: Loss of Use',
                    rules: 'required|min_value:0|max_value:99999999',
                    short: true,
                    col: 12,
                    prefix: '$',
                    currency: true,
                    disabled: _vm.disabled,
                    min: 0,
                    allowNegative: false,
                    defaultValue: 0
                }
            }},on:{"change":function($event){return _vm.coverageChange('D', $event)}}})],1),_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"3","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovDPercent',
                type: 'PercentIncrementer',
                value: _vm.Percents.D,
                config: {
                  disabled: _vm.disabled,
                  short: true
                }
            }},on:{"change":function($event){return _vm.percentChange('D', $event)}}})],1)],1):_vm._e(),(_vm.showCovRow('Edp3'))?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovEdp3',
                type: 'TextInput',
                value: _vm.Coverages.Edp3,
                //DataServiceValue: CoverageObjects.Edp3.DataServiceValue,
                //DataServiceSource: CoverageObjects.Edp3.DataServiceSource,
                config: {
                    label: 'Coverage E: Additional Living Expense',
                    rules: 'required|min_value:0',
                    short: true,
                    col: 12,
                    prefix: '$',
                    currency: true,
                    disabled: _vm.disabled,
                    min: 0,
                    allowNegative: false,
                    defaultValue: 0
                }
            }},on:{"change":function($event){return _vm.coverageChange('Edp3', $event)}}})],1),_c('v-col',{staticClass:"py-0 pb-sm-4 pb-xs-4",attrs:{"md":"3","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'CovEdp3Percent',
                type: 'PercentIncrementer',
                value: _vm.Percents.Edp3,
                config: {
                  disabled: _vm.disabled,
                  short: true
                }
            }},on:{"change":function($event){return _vm.percentChange('Edp3', $event)}}})],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }