<template>
  <div v-if="showControl">
    <ValidationObserver tag="span" ref="covObserver">
      <v-row class="my-0" v-if="showCovRow('A')">
        <v-col md="9" cols="12" class="py-0 pb-sm-4 pb-xs-4">
          <DynamicFormControl 
              :control="{
                  name: 'CovA',
                  type: 'TextInput',
                  value: Coverages.A,
                  //DataServiceValue: CoverageObjects.A.DataServiceValue,
                  //DataServiceSource: CoverageObjects.A.DataServiceSource,
                  config: {
                      label: 'Coverage A: Dwelling',
                      rules: 'required|min_value:1|max_value:99999999',
                      short: true,
                      col: 12,
                      prefix: '$',
                      default: 0,
                      currency: true,
                      disabled: disabled,
                      dataServiceValueLabel: 'Suggested Coverage A',
                      dataServiceValueFormat: 'currency',
                      min: 0,
                      allowNegative: false,
                      defaultValue: 0,
                      hideDataServiceValue: true,
                      ignoreDataServiceValue: true
                  }
              }"
              @change="coverageChange('A', $event)"
              @keydown="CovAKeydown"
              @blur="CovABlur" 
          />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="showCovAAttestation">
        <v-col md="12" cols="12" class="py-0 pb-2">
          <DynamicFormControl 
              :control="{
                  name: 'CovAAttestation',
                  type: 'Attestation',
                  value: CovAAttestation,
                  config: {
                      label: CovAAttestationLabel,
                      rules: 'required',
                      col: 12,
                      disabled: disabled,
                      short: true
                  }
              }"
              @change="CovAAttestationChange($event)"
          />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="showCovRow('B')" >
        <v-col md="6" cols="12" class="py-0">
          <DynamicFormControl 
              :control="{
                  name: 'CovB',
                  type: 'TextInput',
                  value: Coverages.B,
                  //DataServiceValue: CoverageObjects.B.DataServiceValue,
                  //DataServiceSource: CoverageObjects.B.DataServiceSource,
                  config: {
                      label: 'Coverage B: Other Structures',
                      rules: 'required|max_value:99999999',
                      short: true,
                      col: 12,
                      prefix: '$',
                      currency: true,
                      disabled: disabled,
                      min: 0,
                      allowNegative: false,
                      defaultValue: 0
                  }
              }"
              @change="coverageChange('B', $event)" 
          />
        </v-col>
        <v-col md="3" cols="12" class="py-0 pb-sm-4 pb-xs-4">
          <DynamicFormControl 
              :control="{
                  name: 'CovBPercent',
                  type: 'PercentIncrementer',
                  value: Percents.B,
                  config: {
                    disabled: disabled,
                    short: true
                  }
              }"
              @change="percentChange('B', $event)" 
          />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="showCovRow('C')" >
        <v-col :md="CoverageOption == 'ho4' ? 9 : 6" cols="12" class="py-0">
          <DynamicFormControl 
              :control="{
                  name: 'CovC',
                  type: 'TextInput',
                  value: Coverages.C,
                  //DataServiceValue: CoverageObjects.C.DataServiceValue,
                  //DataServiceSource: CoverageObjects.C.DataServiceSource,
                  config: {
                      label: 'Coverage C: Personal Property',
                      rules: 'required|min_value:0|max_value:99999999',
                      short: true,
                      col: 12,
                      prefix: '$',
                      currency: true,
                      disabled: disabled,
                      min: 0,
                      allowNegative: false,
                      defaultValue: 0
                  }
              }"
              @change="coverageChange('C', $event)" 
              @keydown="CovCKeydown" 
          />
        </v-col>
        <v-col md="3" cols="12" class="py-0 pb-sm-4 pb-xs-4" v-if="CoverageOption != 'ho4'">
          <DynamicFormControl 
              :control="{
                  name: 'CovCPercent',
                  type: 'PercentIncrementer',
                  value: Percents.C,
                  config: {
                    disabled: disabled,
                    short: true
                  }
              }"
              @change="percentChange('C', $event)" 
          />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="showCovRow('D')" >
        <v-col md="6" cols="12" class="py-0">
          <DynamicFormControl 
              :control="{
                  name: 'CovD',
                  type: 'TextInput',
                  value: Coverages.D,
                  //DataServiceValue: CoverageObjects.D.DataServiceValue,
                  //DataServiceSource: CoverageObjects.D.DataServiceSource,
                  config: {
                      label: CoverageOption === 'dp3' ? 'Coverage D: Fair Rental Value' : 'Coverage D: Loss of Use',
                      rules: 'required|min_value:0|max_value:99999999',
                      short: true,
                      col: 12,
                      prefix: '$',
                      currency: true,
                      disabled: disabled,
                      min: 0,
                      allowNegative: false,
                      defaultValue: 0
                  }
              }"
              @change="coverageChange('D', $event)" 
          />
        </v-col>
        <v-col md="3" cols="12" class="py-0 pb-sm-4 pb-xs-4">
          <DynamicFormControl 
              :control="{
                  name: 'CovDPercent',
                  type: 'PercentIncrementer',
                  value: Percents.D,
                  config: {
                    disabled: disabled,
                    short: true
                  }
              }"
              @change="percentChange('D', $event)" 
          />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="showCovRow('Edp3')" >
        <v-col md="6" cols="12" class="py-0">
          <DynamicFormControl 
              :control="{
                  name: 'CovEdp3',
                  type: 'TextInput',
                  value: Coverages.Edp3,
                  //DataServiceValue: CoverageObjects.Edp3.DataServiceValue,
                  //DataServiceSource: CoverageObjects.Edp3.DataServiceSource,
                  config: {
                      label: 'Coverage E: Additional Living Expense',
                      rules: 'required|min_value:0',
                      short: true,
                      col: 12,
                      prefix: '$',
                      currency: true,
                      disabled: disabled,
                      min: 0,
                      allowNegative: false,
                      defaultValue: 0
                  }
              }"
              @change="coverageChange('Edp3', $event)" 
          />
        </v-col>
        <v-col md="3" cols="12" class="py-0 pb-sm-4 pb-xs-4">
          <DynamicFormControl 
              :control="{
                  name: 'CovEdp3Percent',
                  type: 'PercentIncrementer',
                  value: Percents.Edp3,
                  config: {
                    disabled: disabled,
                    short: true
                  }
              }"
              @change="percentChange('Edp3', $event)" 
          />
        </v-col>
      </v-row>
    </ValidationObserver>
  </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { formatMoney } from '@/scripts/helper'

export default {
  name: "Coverages",
  components: {
    DynamicFormControl
  },
  props: {
    module: String,
    disabled: Boolean,
  },
  data() {
    return {
      CoverageOption: null,
      showControl: false,
      CovAAttestation: null,
      showCovAAttestation: false,
      CoverageObjects: {
        A: {},
        B: {},
        C: {},
        D: {},
        Edp3: {}
      },
      Coverages: {
        A: null,
        B: null,
        C: null,
        D: null,
        Edp3: null,
      },
      Percents: {
        A: null,
        B: null,
        C: null,
        D: null,
        Edp3: null
      }
    }
  },
  methods: {
    showCovRow(type) {
      if (type === 'A') {
        return ['ho3', 'ho5', 'ho6', 'ho3_windonly', 'ho6_windonly', 'dp3'].indexOf(this.CoverageOption) > -1
      } else if (type === 'B') {
        return ['ho3', 'ho5', 'ho3_windonly', 'dp3'].indexOf(this.CoverageOption) > -1
      } else if (type === 'C' || type === 'D') {
        return ['ho3', 'ho5', 'ho4', 'ho6', 'ho3_windonly', 'ho6_windonly', 'dp3'].indexOf(this.CoverageOption) > -1
      } else if (type == 'Edp3') {
        return this.CoverageOption == 'dp3';
      }
      return false
    },
    getDefaultPercents() {
      switch (this.CoverageOption) {
        case 'ho3':
        case 'ho5':
        case 'ho3_windonly':
          return {
            B: 10,
            C: 45,
            D: 15,
            Edp3: 0
          }
        case 'ho4':
          return {
            B: 0,
            C: 0,
            D: 15,
            Edp3: 0
          }
        case 'ho6':
        case 'ho6_windonly':
          return {
            B: 0,
            C: 45,
            D: 15,
            Edp3: 0
          }
        case 'dp3':
          return {
            B: 10,
            C: 45,
            D: 20,
            Edp3: 0
          }
        default:
          return {
            B: 0,
            C: 0,
            D: 0,
            Edp3: 0
          }
      }
    },
    updateCoverageControl(cov, value) {
      this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'Cov' + cov,
          value: value
      });
    },
    CovAAttestationChange(event) {
      this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'CovAAttestation',
          value: event.value
      });
    },
    coverageChange(cov, event) {
      if (!this.showCovRow(cov)) return;
      if (event.value == this.Coverages[cov]) return;
      this.updateCoverageControl(cov, event.value);
      setTimeout(() => {
        this.updatePercent(cov);
      }, 200)
    },
    percentChange(cov, event) {
      if (this.Percents[cov] == event.value) return;
      this.Percents[cov] = event.value;
      this.Coverages[cov] = Math.round((this.Percents[cov] / 100) * this.BaseCoverage);
      this.updateCoverageControl(cov, this.Coverages[cov]);
    },
    updatePercent(cov) {
      if (this.BaseCoverage === 0 || !this.BaseCoverage) return;
      if (!this.showCovRow(cov)) return;

      this.Percents[cov] = Math.round((this.Coverages[cov] / this.BaseCoverage) * 100);
      if (this.CoverageOption === 'ho4') {
        this.CovCKeydown();
      }
    },
    CovABlur() {

      //Update CovA min/max metadata after CovA control is blurred/changed
      if (this.Coverages.A || this.Coverages.A === 0) {

        const covAControl = this.CoverageObjects.A

        let covAMetadata = {};
        if (covAControl.metadata) {
          covAMetadata = covAControl.metadata;
        }

        if (covAMetadata.min === undefined || covAMetadata.min === null || this.Coverages.A < parseInt(covAMetadata.min)) {
          covAMetadata.min = this.Coverages.A;
        }

        if (covAMetadata.max === undefined || covAMetadata.max === null || this.Coverages.A > parseInt(covAMetadata.max)) {
          covAMetadata.max = this.Coverages.A;
        }

        this.$store.commit('updateFormControlMetadata', {
          module: this.module,
          name: 'CovA',
          metadata: covAMetadata
        });
      }

      
      //Update metadata for CovAAttestation when control is shown
      this.showCovAAttestation = this.checkShowCovAAttestation();

      if (this.showCovAAttestation) {
        this.$store.commit('updateFormControlMetadata', {
          module: this.module,
          name: 'CovAAttestation',
          metadata: {
            presented: true
          }
        });
      } else {
        this.CovAAttestationChange({value: null});
      }
    },
    CovAKeydown() {
      setTimeout(() => {
        if (this.CoverageOption !== 'ho4') {
          this.CovAChange();
        } 
      }, 100)
    },
    CovCKeydown() {
      setTimeout(() => {
        if (this.CoverageOption === 'ho4') {
          this.CovCChange();
        }
      }, 100)
    },
    CovAChange() {
      if (this.CoverageOption == 'ho4') return;

      if (!this.CovA) {
        this.Coverages.A = 0;
      }

      Object.keys(this.Coverages).forEach(cov => {
        if (this.showCovRow(cov) && cov !== 'A') {
          const newVal = this.CovA ? Math.round(this.CovA * this.Percents[cov] / 100) : 0;
          this.Coverages[cov] = newVal;
          this.updateCoverageControl(cov, newVal);
        }
      })
    },
    CovCChange() {
      if (this.CoverageOption !== 'ho4') return;

      if (!this.CovC) {
        this.Coverages.C = 0;
      }

      Object.keys(this.Coverages).forEach(cov => {
        if (this.showCovRow(cov) && cov !== 'C') {
          const newVal = this.CovC ? Math.round(this.CovC * this.Percents[cov] / 100) : 0;
          this.Coverages[cov] = newVal;
          this.updateCoverageControl(cov, newVal);
        }
      })
    },
    checkShowCovAAttestation() {
      let show = false;
      if (
      this.CoverageObjects &&
      this.CoverageObjects.A && 
      this.CoverageObjects.A.DataServiceValue && 
      ['ho3', 'ho3_windonly', 'dp3'].indexOf(this.CoverageOption) > -1 &&
      this.Coverages.A > 0 &&
      this.Coverages.A < (this.CoverageObjects.A.DataServiceValue * .9)) {
        show = true;
      }

      return show;
    },
    initialize() {
      
      if (!this.CovB && !this.CovC && !this.CovD) {
        let defaultPercents = this.getDefaultPercents();
        this.Percents = {...defaultPercents};
        this.showControl = true;

        this.$nextTick(() => {
          this.CovAChange();
          this.CovCChange();
        })

      } else {
        this.updatePercent('B');
        this.updatePercent('C');
        this.updatePercent('D');
        this.updatePercent('Edp3');

        this.showControl = true;
      }

      this.CovABlur();

    },
    syncControlValues() {
      const CovA = this.$store.getters.getControlValue(this.module, 'CovA')
      const CovB = this.$store.getters.getControlValue(this.module, 'CovB')
      const CovC = this.$store.getters.getControlValue(this.module, 'CovC')
      const CovD = this.$store.getters.getControlValue(this.module, 'CovD')
      const CovEdp3 = this.$store.getters.getControlValue(this.module, 'CovEdp3')

      if ((CovA || CovA === 0) && CovA !== this.CovA) this.Coverages.A = CovA;
      if ((CovB || CovB === 0) && CovB !== this.CovB) this.Coverages.B = CovB;
      if ((CovC || CovC === 0) && CovC !== this.CovC) this.Coverages.C = CovC;
      if ((CovD || CovD === 0) && CovD !== this.CovD) this.Coverages.D = CovD;
      if ((CovEdp3 || CovEdp3 === 0) && CovEdp3 !== this.CovEdp3) this.Coverages.Edp3 = CovEdp3;


      this.CoverageObjects.A = this.$store.getters.getControl(this.module, 'CovA')
      this.CoverageObjects.B = this.$store.getters.getControl(this.module, 'CovB')
      this.CoverageObjects.C = this.$store.getters.getControl(this.module, 'CovC')
      this.CoverageObjects.D = this.$store.getters.getControl(this.module, 'CovD')
      this.CoverageObjects.Edp3 = this.$store.getters.getControl(this.module, 'CovEdp3')

      this.CovAAttestation = this.$store.getters.getControlValue(this.module, 'CovAAttestation');

      this.$forceUpdate();
    }
  },
  computed: {
    CovA() {
      return this.Coverages.A;
    },
    CovB() {
      return this.Coverages.B;
    },
    CovC() {
      return this.Coverages.C;
    },
    CovD() {
      return this.Coverages.D;
    },
    CovEdp3() {
      return this.Coverages.Edp3;
    },
    BaseCoverage() {
      return (this.CoverageOption === 'ho4')
        ? Math.round(Number(this.CovC)) || 0
        : Math.round(Number(this.CovA)) || 0
    },
    CovAAttestationLabel() {
      return "Public records indicate the property is valued at <strong>" + formatMoney(this.CoverageObjects.A.DataServiceValue) + "</strong>. Please confirm your valuation is accurate.";
    }
  },
  mounted() {
    this.CoverageOption = this.$store.getters.getControlValue(this.module, 'CoverageOption')
    
    this.$store.watch(() => {
      this.syncControlValues();
    }, () => {},
    {
      immediate: true
    });

    this.initialize();

  },
}
</script>
